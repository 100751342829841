import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '@employee-view/api';
import { Modes, PlacesInputPipe } from '@shared/utils';
import { MaterialModule } from '@ui-library/modules/material/material.module';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'employee-edit',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MaterialModule],
    templateUrl: './employee-edit.component.html',
    styleUrls: ['./employee-edit.component.scss'],
})
export class EmployeeEditComponent implements OnInit, AfterViewInit {
    @ViewChild('addressSearch', { static: false }) public addressSearch?: ElementRef;

    @Output() public cancel = new EventEmitter<void>();
    @Input() set address(value: string) {
        this.addressInputControl.setValue(value);
    }
    @Input() public saveFn: (value: Employee) => void;
    @Input() public saving = false;
    @Input() public employee: Partial<Employee>;

    public modeOptions = {
        [Modes.BIKE]: $localize`:@@bike:Bike`,
        [Modes.PUBLIC_TRANSPORT]: $localize`:@@publicTransport:Public Transport`,
        [Modes.WALK]: $localize`:@@walk:Walk`,
        [Modes.CAR_PASSENGER]: $localize`:@@carPassenger:Car Passenger`,
        [Modes.CAR_DRIVER]: $localize`:@@carDriver:Car Driver`,
        [Modes.UNKNOWN]: $localize`:@@unknown:Unknown`,
    };

    public loading = false;
    public employeeForm: FormGroup;
    public addressInputControl = new FormControl('');
    public placesResults: google.maps.places.QueryAutocompletePrediction[] = [];
    public error = '';

    constructor(private cdf: ChangeDetectorRef, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.employeeForm = this.fb.nonNullable.group({
            street: [''],
            city: [''],
            zipCode: [''],
            personnelNumber: [''],
            currentMode: [''],
            impossibleModes: [[]],
            startTime: [''],
            endTime: [''],
            longitude: [],
            latitude: [],
        });

        if (this.employee) {
            this.employeeForm.patchValue(this.employee);
        }

        this.addressInputControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(value => {
                if (value) {
                    this.getPlacePredictions(value);
                } else {
                    this.placesResults = [];
                }
            });
    }

    ngAfterViewInit(): void {
        if (!this.employee) {
            this.addressSearch?.nativeElement.focus();
            this.getPlacePredictions(this.addressInputControl.value || '');
            this.cdf.detectChanges();
        }
    }

    public getPlacePredictions(value: string): void {
        this.loading = true;

        const biasBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(48.2082, 16.3738), // Vienna
            new google.maps.LatLng(52.52, 13.405), // Berlin
        );

        const service = new google.maps.places.AutocompleteService();

        service.getQueryPredictions({ input: value || '', bounds: biasBounds }, (results = []) => {
            if (results) {
                this.placesResults = results;
                this.loading = false;
                this.cdf.detectChanges();
            }
        });
    }

    public getPlace(placeId: string): void {
        const placeService = new google.maps.places.PlacesService(
            this.addressSearch?.nativeElement,
        );
        placeService.getDetails({ placeId: placeId || '' }, place => {
            if (place != null) {
                const placesPipe: PlacesInputPipe = new PlacesInputPipe();
                const streetNumber: string = placesPipe.transform(place, 'street_number');
                const street: string = placesPipe.transform(place, 'route');
                this.employeeForm
                    .get('street')
                    ?.setValue(streetNumber == null ? street : street + ' ' + streetNumber);
                this.employeeForm.get('city')?.setValue(placesPipe.transform(place, 'locality'));
                this.employeeForm.get('country')?.setValue(placesPipe.transform(place, 'country'));
                this.employeeForm
                    .get('zipCode')
                    ?.setValue(placesPipe.transform(place, 'postal_code'));

                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();
                if (lat && lng) {
                    this.employeeForm.get('latitude')?.setValue(lat);
                    this.employeeForm.get('longitude')?.setValue(lng);
                }
            }
        });
    }

    public saveEmployee(): void {
        if (this.employeeForm.invalid) {
            this.error = 'Please fill out all required fields.';

            return;
        }
        if (this.employeeForm.value.impossibleModes.includes(this.employeeForm.value.currentMode)) {
            this.error = 'Current mode cannot be in the impossible modes.';

            return;
        }
        this.saveFn(this.employeeForm.value);
    }
}
