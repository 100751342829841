/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Employee } from '../models/employee';
import { EmployeeSearchResult } from '../models/employee-search-result';
import { IdInfo } from '../models/id-info';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployee
   */
  static readonly GetEmployeePath = '/api/v1/locations/{locationId}/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployee$Response(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetEmployeePath, 'get');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployee(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
  }
): Observable<Employee> {

    return this.getEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation updateEmployee
   */
  static readonly UpdateEmployeePath = '/api/v1/locations/{locationId}/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee$Response(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
    body: Employee
  }
): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.UpdateEmployeePath, 'put');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.path('locationId', params.locationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
    body: Employee
  }
): Observable<Employee> {

    return this.updateEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation deleteEmployee
   */
  static readonly DeleteEmployeePath = '/api/v1/locations/{locationId}/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployee$Response(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.DeleteEmployeePath, 'delete');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployee(params: {
    employeeId: string;
    locationId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getEmployees
   */
  static readonly GetEmployeesPath = '/api/v1/locations/{locationId}/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees$Response(params: {
    locationId: string;
    size?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetEmployeesPath, 'get');
    if (params) {
      rb.path('locationId', params.locationId, {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees(params: {
    locationId: string;
    size?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<Array<Employee>> {

    return this.getEmployees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation createEmployee
   */
  static readonly CreateEmployeePath = '/api/v1/locations/{locationId}/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEmployee$Response(params: {
    locationId: string;
    context?: HttpContext
    body: Employee
  }
): Observable<StrictHttpResponse<IdInfo>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.CreateEmployeePath, 'post');
    if (params) {
      rb.path('locationId', params.locationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IdInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEmployee(params: {
    locationId: string;
    context?: HttpContext
    body: Employee
  }
): Observable<IdInfo> {

    return this.createEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<IdInfo>) => r.body as IdInfo)
    );
  }

  /**
   * Path part for operation searchEmployees
   */
  static readonly SearchEmployeesPath = '/api/v1/locations/{locationId}/employees/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchEmployees$Response(params: {
    locationId: string;
    query: string;
    size?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EmployeeSearchResult>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.SearchEmployeesPath, 'get');
    if (params) {
      rb.path('locationId', params.locationId, {});
      rb.query('query', params.query, {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeSearchResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchEmployees(params: {
    locationId: string;
    query: string;
    size?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<Array<EmployeeSearchResult>> {

    return this.searchEmployees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeSearchResult>>) => r.body as Array<EmployeeSearchResult>)
    );
  }

}
