import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Trip } from '@employee-view/api';
import { NormalizeValuesPipe, NumberUnit } from '@shared/utils';
import { MaterialModule } from '@ui-library/modules/material/material.module';
import { UiLibraryModule } from '@ui-library/ui-library.module';

@Component({
    selector: 'trip-card',
    standalone: true,
    imports: [CommonModule, UiLibraryModule, MaterialModule, NormalizeValuesPipe],
    providers: [DecimalPipe, NormalizeValuesPipe],
    templateUrl: './trip-card.component.html',
    styleUrls: ['./trip-card.component.scss'],
})
export class TripCardComponent {
    @Input() public trip: Trip;
    @Input() public isUsualMode = false;
    @Input() public isSustainableMode = false;
    @Input() public isSelected = false;
    @Output() public selectTripId = new EventEmitter<string>();

    public NumberUnit = NumberUnit;

    public colors = {
        WALK: 'bg-transport-walk',
        BIKE: 'bg-transport-bike',
        PUBLIC_TRANSPORT: 'bg-transport-public-transport',
        CAR_DRIVER: 'bg-transport-car-driver',
        CAR_PASSENGER: 'bg-transport-car-passenger',
        UNKNOWN: 'bg-transport-unknown',
    };
}
