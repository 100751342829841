import { Injectable } from '@angular/core';
import { StrictHttpResponse, UserInfo, UserService } from '@employee-view/api';
import { AbstractApiWrapperService } from '@shared/utils';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EmployeeViewApiWrapperService extends AbstractApiWrapperService<
    StrictHttpResponse<UserInfo>,
    UserInfo
> {
    constructor(private readonly userService: UserService) {
        super();
    }

    public loginPost(username: string, password: string): Observable<StrictHttpResponse<UserInfo>> {
        const params: {
            body: {
                username: string;
                password: string;
            };
        } = {
            body: {
                username,
                password,
            },
        };

        return this.userService.login$Response(params);
    }

    public getUserInfo(): Observable<UserInfo> {
        return this.userService.userInfo();
    }
}
