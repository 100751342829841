import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NumberUnit } from '../enums';
import { convertTime, getMeasure, getNumberAbbreviation, getSwitchedValue } from '../functions';

@Pipe({
    name: 'normalizeValues',
    standalone: true,
})
export class NormalizeValuesPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: number, unit: NumberUnit, digitsInfo: string = '1.0-2'): string {
        let formattedValues: string[] = [];

        // previous pipes might transform value to string --> make sure its a number
        value = +value;

        if (
            unit === NumberUnit.DISTANCE ||
            unit === NumberUnit.WEIGHT ||
            unit === NumberUnit.MONEY ||
            unit === NumberUnit.SMALL_WEIGHT
        ) {
            const updatedValue: number = getSwitchedValue(value, unit);
            const measure: string = getMeasure(value, unit);
            const numberAbbr: string = getNumberAbbreviation(value);

            formattedValues = [
                `${this.decimalPipe.transform(updatedValue, digitsInfo)}${numberAbbr}`,
                measure,
            ];
        } else if (unit === NumberUnit.TIME) {
            value = Math.round(+value);

            const { days, hours, minutes }: { days: number; hours: number; minutes: number } =
                convertTime(value);
            formattedValues = [];

            if (days > 0) {
                formattedValues.push(`${this.decimalPipe.transform(days, '1.0-0')}`, 'd');
            }
            if (hours > 0) {
                formattedValues.push(`${this.decimalPipe.transform(hours, '1.0-0')}`, 'h');
            }
            if (minutes > 0 || (days === 0 && hours === 0 && minutes === 0)) {
                formattedValues.push(`${this.decimalPipe.transform(minutes, '1.0-0')}`, 'min');
            }
        } else if (unit === NumberUnit.PERCENTAGE) {
            const updatedValue: number = getSwitchedValue(value, unit);
            const measure: string = getMeasure(value, unit);
            const numberAbbr: string = getNumberAbbreviation(value);
            formattedValues = [
                `${this.decimalPipe.transform(updatedValue, digitsInfo)}${numberAbbr}`,
                measure,
            ];
        } else {
            const updatedValue: number = getSwitchedValue(value, unit);
            const numberAbbr: string = getNumberAbbreviation(value);
            formattedValues = [
                `${this.decimalPipe.transform(updatedValue, digitsInfo)}${numberAbbr}`,
                unit,
            ];
        }

        return formattedValues.join(' ');
    }
}
