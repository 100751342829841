<mat-form-field>
    <mat-label i18n="@@personalAddress">Personal Address</mat-label>
    <input
        #addressSearch
        [formControl]="addressInputControl"
        matInput
        [matAutocomplete]="auto"
        required />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngIf="loading === true; else results" class="loading" disabled>
            <mat-spinner diameter="20"></mat-spinner>
        </mat-option>
        <ng-template #results>
            <mat-option
                (onSelectionChange)="getPlace(searchResult.place_id || '')"
                *ngFor="let searchResult of placesResults"
                [value]="searchResult.description">
                <div class="flex items-center gap-xs">
                    <mat-icon>place</mat-icon>
                    <span class="w-full overflow-hidden">{{ searchResult.description }} </span>
                </div>
            </mat-option>
        </ng-template>
    </mat-autocomplete>
</mat-form-field>

<form [formGroup]="employeeForm">
    <mat-form-field>
        <mat-label i18n="@@currentMode">Current Mode</mat-label>
        <mat-select placeholder="None" formControlName="currentMode" required>
            <mat-option *ngFor="let option of modeOptions | keyvalue" [value]="option.key">
                {{ option.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n="@@employeeId">Employee Id</mat-label>
        <input matInput formControlName="personnelNumber" required />
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n="@@impossibleModes">Impossible Modes</mat-label>
        <mat-select placeholder="None" formControlName="impossibleModes" multiple required>
            <mat-option *ngFor="let option of modeOptions | keyvalue" [value]="option.key">
                {{ option.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n="@@workStartTime">Work Start Time</mat-label>
        <input formControlName="startTime" matInput type="time" required />
    </mat-form-field>

    <mat-form-field>
        <mat-label i18n="@@workEndTime">Work End Time</mat-label>
        <input formControlName="endTime" matInput type="time" required />
    </mat-form-field>
</form>

<mat-error class="mb-m" *ngIf="error"> {{ error }} </mat-error>

<div class="flex justify-between">
    <button mat-stroked-button color="primary" (click)="cancel.emit()" i18n="@@discard">
        DISCARD
    </button>
    <button [disabled]="saving" mat-raised-button color="primary" (click)="saveEmployee()">
        <mat-spinner [diameter]="24" *ngIf="saving"></mat-spinner>
        <span *ngIf="!saving" i18n="@@save">SAVE</span>
    </button>
</div>
