import { inject } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { AuthGuard } from '@auth/feature-auth';

export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'trips',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('@auth/feature-auth').then(m => m.AuthModule),
        canMatch: [() => inject(AuthGuard).isLoggedOut()],
        data: {
            redirect: '/trips',
        },
    },
    {
        path: 'trips',
        loadChildren: () =>
            import('@employee-view/trips/feature-trips').then(m => m.featureTripsRoutes),
        canMatch: [
            (route: Route, segments: UrlSegment[]) => inject(AuthGuard).isLoggedIn(route, segments),
        ],
    },
];
