<div
    class="rounded-lg shadow-md mb-l p-m hover:shadow-md cursor-pointer"
    [ngClass]="{
        'bg-grey-superlight': isSelected,
        'bg-grey-ultralight': !isSelected
    }"
    (click)="selectTripId.emit(trip.id || '')">
    <div class="flex my-m justify-between items-center">
        <div class="flex flex-col">
            <div class="flex items-center">
                <ng-container *ngIf="trip?.transportMode as mode">
                    <div class="w-m h-m rounded-full mr-xs" [class]="colors[mode]"></div>
                </ng-container>
                <div class="tps-heading-5">
                    <div [ngSwitch]="trip.transportMode">
                        <div *ngSwitchCase="'WALK'" i18n="@@walk">Walk</div>
                        <div *ngSwitchCase="'BIKE'" i18n="@@bike">Bike</div>
                        <div *ngSwitchCase="'PUBLIC_TRANSPORT'" i18n="@@publicTransport">
                            Public Transport
                        </div>
                        <div *ngSwitchCase="'CAR_DRIVER'" i18n="@@carDriver">Car Driver</div>
                        <div *ngSwitchCase="'CAR_PASSENGER'" i18n="@@carPassenger">
                            Car Passenger
                        </div>
                    </div>
                </div>
            </div>
            <div class="tps-sub-heading-2 text-grey-medium" *ngIf="isUsualMode" i18n="@@usualMode">
                (Usual Mode)
            </div>
        </div>
        <div
            class="bg-green-light border-green-medium h-full py-xs px-l text-left rounded-lg flex items-center"
            *ngIf="isSustainableMode">
            <mat-icon class="mr-s">light_mode</mat-icon>
            <div class="tps-body" i18n="@@mostSustainableMode">Most sustainable option</div>
        </div>
    </div>

    <div class="overflow-y-auto mb-m flex flex-col gap-s">
        <div class="flex items-center">
            <div
                class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center"
                i18n="@@duration">
                <mat-icon class="mr-s">route</mat-icon> Duration:
            </div>
            <div class="w-1/2">
                {{ trip.duration | normalizeValues : NumberUnit.TIME }}
            </div>
        </div>
        <div class="flex items-center">
            <div
                class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center"
                i18n="@@costInfo">
                <mat-icon class="mr-s">euro_symbol_outline</mat-icon> Cost:
            </div>
            <div class="w-1/2">
                {{ trip.costs ? (trip.costs | normalizeValues : NumberUnit.MONEY) : 'no costs' }}
            </div>
        </div>
        <div class="flex items-center" *ngIf="trip.distance">
            <div
                class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center"
                i18n="@@distance">
                <mat-icon class="mr-s">schedule_outline</mat-icon> Distance:
            </div>
            <div class="w-1/2">
                {{ trip.distance | normalizeValues : NumberUnit.DISTANCE }}
            </div>
        </div>
        <div class="flex">
            <div
                class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center"
                i18n="@@co2emissions">
                <mat-icon class="mr-s">eco_outline</mat-icon> CO Emissions:
            </div>
            <div class="w-1/2" *ngIf="trip.emissions">
                {{ trip.emissions | normalizeValues : NumberUnit.SMALL_WEIGHT }}
                CO<sub>2</sub>e
            </div>
            <div class="w-1/2" *ngIf="!trip.emissions" i18n="@@noEmissions">no emissions</div>
        </div>
    </div>

    <!-- once we have the updated design for legs in public transport -->
    <!-- <div
    class="overflow-y-auto mb-m flex flex-col gap-s"
    *ngFor="let leg of trip?.legs; let last = last">
    <div class="flex items-center">
        <div class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center">
            <mat-icon class="mr-s">route</mat-icon> Duration:
        </div>
        <div class="w-1/2">
            {{ leg.duration | number : '1.0-0' }} minutes
            {{
                trip?.transportMode === 'PUBLIC_TRANSPORT' && leg.detailedMode
                    ? '(' + leg.detailedMode.split('_').join(' ') + ')'
                    : ''
            }}
        </div>
    </div>
    <div class="flex items-center">
        <div class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center">
            <mat-icon class="mr-s">euro_symbol_outline</mat-icon> Cost:
        </div>
        <div class="w-1/2">
            {{ leg.costs ? (leg.costs | number : '1.0-0') + ' €' : 'no costs' }}
            {{
                trip?.transportMode === 'PUBLIC_TRANSPORT' && leg.detailedMode
                    ? '(' + leg.detailedMode.split('_').join(' ') + ')'
                    : ''
            }}
        </div>
    </div>
    <div class="flex items-center" *ngIf="leg.distance">
        <div class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center">
            <mat-icon class="mr-s">schedule_outline</mat-icon> Distance:
        </div>
        <div class="w-1/2">{{ leg.distance | number : '1.0-0' }} meters</div>
    </div>
    <div class="flex">
        <div class="w-1/2 tps-sub-heading-1 text-dark font-bold flex items-center">
            <mat-icon class="mr-s">eco_outline</mat-icon> Emissions:
        </div>
        <div class="w-1/2" *ngIf="trip.emissions">
            {{ trip.emissions | number : '1.0-0' }} kg CO<sub>2</sub>e
        </div>
        <div class="w-1/2" *ngIf="!trip.emissions">No Emissions</div>
    </div>

    <hr class="my-m" *ngIf="!last" />
</div> -->
</div>
